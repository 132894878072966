import React from "react";
import SentenceTranslationComponent from "../components/SentenceTranslationComponent";
export default function PagePoem() {
  return (
    <SentenceTranslationComponent
      title="Lakam le Akhiatna"
      docType="poem"
    />
  );
}
