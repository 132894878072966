import React from 'react';
import NavigationWrapper from '../components/NavigationWrapper';
import {
    Typography,
    Divider
} from '@material-ui/core/';

import GitHubIcon from '@material-ui/icons/GitHub';

const PageAbout=()=>{
    return(
        <NavigationWrapper>
                <Typography variant="h6" gutterBottom>TONGLET’ NUNGTHU</Typography>
                <Divider /><br/>
                <Typography paragraph>
                    <strong>Tonglet</strong> in khiatna nih nei-a: ‘kampau namkhat pan adangkhat-ah letkhia’ cihna leh ‘kampau namkhat aading-in khutlet’ cihna hi. Tonglet pen anuai-a tupna (4) tawh Myanmar gam Yangon khuapi aa om khangno pawlkhat in semkhawm-in hong patkhiat ahi hi. 
                </Typography>

                <Typography paragraph>
                    <strong>Tonglet tupna (1): Info-tech khantohna zui-in Tonglet neih zawh nading.</strong> Zomi-te lak-ah manglai leh mikang-pau sin theihna ding le zolai leh zopau sintheihna dingin a zahtakhuai mi pawlkhat in apilna, atha, le asum uh tampi bei-in zolai pan manglai / manglai pan zolai cih bangin tonglet na khenkhia uh hi. Ahi zongin tuhun info-tech khantohna hong manlang lua mahmah ahih manin, mikang pau-le-lai sinna leh zo pau-le-lai sinna te-ah a kizang laibute sim tangtang ding hamsa kisa tektek-in info-tech tungah kikoih digital lai-te asim mi khan cihtak-in hong khang hi. Mi tampi in smart phone vive hong zat takteh, digital laite sim ding hong baih zawkan lai hi. Tua hi-a, a kikhensa tonglet laibute pen tulai digital hunah alaibu tawh anei zong kitawm ban-ah a laibu tawh hun pia-a kisim ciatciat zo nawn pha lo hi. Akikhensa hih tonglet laibu manpha-te asim tawm semsem leh kisem zo nawn lo semsem ding ahih manin, hih nasep thupi-te mipite in mannei tak leh baihno takin a kizat kik theihna ding tupna lian pi tawh hih web-based / digital tonglet hong ki pankhia hi. 
                </Typography>
                
                <Typography paragraph>
                    <strong>Tonglet tupna (2): Online Zopau khutlet khat neih den nading.</strong> Adiakdiak-in mangpau kizatna gam-ah alal Zomi tampi tak in manglai pen nisim khuasakna, kiho-kizopna leh nasep zonna cihte-ah theih loh apha mawh ahih manin hih tonglet pen amau’ khutlet khat ahih theih nading tupna hi. Tua ban-ah manglai panin zolai a sinnuam namdang kampau zatna aa om Zomi-te aading hi ta leh; lakam, paunak, minte khiatna athei nuamte aadingin zong tonglet pen khutlet khat asuah theih nading tupna hi. 
                </Typography>
                
                <Typography paragraph>
                    <strong>Tonglet tupna (3): Online kammal saal neih den nading.</strong> Leitung kiu (4) aa om Zomi khempeuh in; mikang kammal tawh akinei ih zo kammal tawh akhiatna ih neih nai loh-te ei omna mun tek panin manlang tak leh ol tak-in kaihkhop theihna ding leh, ei siamdeuhna (health, engineer, science, law, business, etc.) kammal-te behlap thei zenzen leng, hunkhat ciangin minam picing khat in aneih dinga kilawm zolai kammal nei zo ding hanga,  Zomi-te’ neihgo (identity) thupi mahmah khat hong suak ding hi. Tua ban-ah akhang akhang, ahun hun-in ki puah toto thei ding cih tupna lianpi ahi hi. 
                </Typography>


                <Typography paragraph>
                    <strong>Tonglet tupna (4): Zattaang Zolai gualhzia le Zolai endik neih khawm nading.</strong> Hunkhat takteh zomi sungah zolai nasep asem phadiak kipawlnate leh mimal aki hel thei teng  kikum khawmin, a kibawlsa laibute siksan-a behlap tuakte behlap-in, a baih pen leh azat nuam pen ding zolai gualhzia (grammar and structure) leh zolai endik (Zolai Editorial) neih zawh na ding tupna lianpi ahi hi. 
                </Typography>

                <Typography paragraph>
                    Tu ciangciang Tonglet-ah kiguang kammal-te pen anuai aa laibute pan kila ahi hi.
                </Typography>

                <Typography paragraph> 
                <ul>
                    <li>Chin-English Dictionary: Tiddim Chin (Pu Vul Za Thang & Pu J. Gin Za Twang, 1975)</li>
                    <li>The New English-Zomi & Zomi-English Dictionary (Dr. Dal Lian HaoKip, 2012) </li>
                    <li>Siamsin Dictionary (Pu Thang Khan Dal, 2017)</li>
                    <li>English-Zokam: Theological Dictionary (Dr. Mang Cin Pau Gualnam 2018)</li>
                    <li>Paunak (Pu Thang Khan Dal, 2010: Zomite’ Zia leh Zat)</li>
                    <li>Lakam leh akhiatnate (R Kham Cin Pau, 1999: , ZOLUS; Namkim Bu, Office of Education, Tedim, September 1972)</li>
                    <li>Minkhiatnate (R Kham Cin Pau, 1999, ZOLUS) </li>
                    
                </ul>
                </Typography>

                <Typography variant="h6" gutterBottom>Kammal tawh Tonglet Hong Panpihte’ Nungthu</Typography>
                <Divider /><br/>

                <Typography variant="h6" gutterBottom>Pu Vul Za Thang (1919 to 1976)</Typography>
                <Typography paragraph> 
                    1919 kumin Tongzang ah suak; 1939 kum in “The office of the Battalion Commandant, Burma Frontier Force, Falam” ah nasep kipan; Falam zatopi ah nasep zom aa gaalpi manteh pahtawina “WAR SERVICE CERTIFICATE” ngah; Gaalpi sung in Chin Leader’s Freedom League ah kihel in Zampi kuam ah senior officer sem; Gaalpi beiteh Falam ah CHIN EDUCATION UPLIFT SOCIETY leh Christian Endeavour (CE) kihel; 1947 kum in Rangoon University kah kik-in 1952 kum B.A., 1963 kum B.Ed., leh 1968 kum B.E.S te zo; Hill-Chin Student’s Union a phuankhia te lak ah na kihel; 1951 kumpi Tedim sangpi ah sia sem-in,  Zo Education Board ah thuvaanpi sem; kaalsim kikhawm THUKITUAKNA ah editor sem ngei; 1955-1969 Khalkha, Tedim, Paletwa leh Thuklai sangpi te-ah Sang Siapi sem; 1972-1975 sung Kawlgam thukhunpi vai-in kumpite kepna-ah na om; 1969-1976 dong Mindat ah siapi leh Township Education Officer sem; 1960-1601 sung Fullbright grantee tawh US ah educational tour va zin; “Kawllai Themnono” (Burmese-Self Taught) laihawm sem; Dr. Eugene J. A. Henderson’ laibu “Tiddim Chin” bawlna na panpih; Pi Ning Za Niang tawh 1944 kum in pumkhat suak-in tapa 2 leh tanu 5 nei uh; 1976 kumin hong nusia hi.  
                </Typography>
      
                
                <Typography variant="h6" gutterBottom>Pu J. Gin Za Twang (1921 to 1990)</Typography>
                <Typography paragraph>
                    1921 kum in Tonzang ah suak; Tonzang, Tedim leh Mandalay ah sang kah; 1953 in Rangoon University pan H/A distinction tawh zo; Western (Chin) Levies, Tedim ah nasep kipan; Gaalpi beiteh pahtawina 1939-45 Star, the Burma Star and War Medal kipia; Gaalpi sungin Japan galkapmangpa, Tiger Inada khut nuai pan Tuikhiang, Tonzang, Tiddim, Suahlim leh Theizang gei Mualpi Campte-ah nasem; Chin Leader’s Freedom League member khat hi; Japan Samurai namsaute’ paangbetna, bawlsiatna tuamtuam thuak; eimi tampi tak thahlup nading pan honkhia; Gaal ven ciang in Falam ah Ministry of Chin Affairs ah sem; “Thukituahna” (Our Forum) a kici kaalsim a kihawm lai na pankhia in editor zong sem; Zo Education Board ah thuvanpi sem kawmin Tedim sang pi ah sia sem; TBA pan kihawm LUNGVAKNA editor zong sem ngei; Laibu tuam tuam bu (20) vaal gelh; 1951 pan in cidamna lam sem in a pension dong in Health Assistant (HA) sem; azi, Pi Ging Khaw Don tawh tapa 4 nei; 1990 kum in Tedim-ah hong nusia hi.
                </Typography>

                <Typography variant="h6" gutterBottom>Dr. Dal Lian Haokip, Ph.D. </Typography>
                <Typography paragraph>
                    1958 October 15 ni-in Tonzang khua-ah piang; Unau 10 lak-ah a  6 na; 1975 July kha in Spicer Memorial College, Pune ah 1979 March kha-in Religious Philosophy major leh Religious History minor tawh BLA degree lukhu khukna ngah; 1981-1982 sung Pune University Archaeology department ah M.A. first class tawh zo; 1986 in Pune University mah pan in Ph.D ngah; 1982 June 27 ni-in a zi, Niangno tawh pumkhat suak; 1983-1986 sung, Ph.D. sin-a a om sungin Mrs. Ellen G. White ii gelh ‘The Patriarchs and Prophets’ kici laimai 800 val apha khat Zokam in “Tuan Pupate leh Kamsangte” ci-in teikhia; 1992 kumin Tedim Chin-English Dictionary (244) kici laibu khat bawl; kum nih nungin Zomite’ Taangthute (248) a kici laibu dang khat bawl leuleu; 1986-1988 sung St. Paul’ Institute, New Lamah ah saang Siapi sem, 1989 January kha-in Blue Star Academy hong kipan; Sanglam nasep hoihna tawh kisai in Friendship Forum of India, New Delhi in 1997 Bharat Excellence Award leh 1998 Glory of India Award tawh pahtawi na ngah; Kum 2001 kum in “Southern Asia Division of Seventh Day Adventist laymen representative in World Headquarters” ah Executive Committee member khat hihna tawh meeting kum 5 sung va kah thei a, sia nu in zong meeting ah zui thei zel; Kum 2005 kum in,  Haggai Institute, Hawaii,USA ah ah kaal 3 sung Chritian leadership training va kah; 2017 kumin The Final Messenger team te’n Indianapolis USA te sponsor na nuai ah revival meeting nei dingin vai pai a, tua sung in USA a Zomi Adventist pawlpite veh kawm leh thugen kawm in 3 vei va pai thei; Lamka khua pan USA  ah a gawm-in vial 11 va pai thei in USA gamdung, gamvai teng khempeuh va hawh kawm zo; Zomite it ahih manin Zopau kizatna khua khat ahi Lamka  ah a zi a tate tawh a teng laitak hi. 
                </Typography>

                <Typography variant="h6" gutterBottom>Pu Thang Khan Dal</Typography>
                <Typography paragraph>
                    Pu Thang Khan Dal, Guite (B.Sc, Mandalay University, 1968-1972 ), Tonzang khua ah suak, 1972 kum Tonzang khuapi, Cooperative Office ah Upper Division Clerk ( UDC ) nasep kipan; zakhanna zui-in Zogam khuapi tuamtuam-ah nasem kawikawi; 1986-1988, Tonzang Township Party Organizing Committee ah member sem; 1988 kum Tonzang Cooperative zum ah sem kik; 2005 kum Senior Officer tawh Chin State Cooperative Office, Hakha zum-ah sem; 2007 in pension la; Zogam Zomi hamphatna dingin SIAMSIN Dictionary banah zolai bu tampitak bawlkhia; 1974 kum Pi Zel Khan Niang tawh pumkhat suak; tanu tapa Vungh Kim Mang, Niiang Lun Cing leh Luai San Niang te nei; tu pa (4) nei-in, Australia  khua Adelaide ah a teng laitak hi.
                </Typography>

                <Typography variant="h6" gutterBottom>Rev. Mang Cin Pau Gualnam, Ph.D.</Typography>
                <Typography paragraph>
                    Mang Cin Pau Gualnam (ThM; PhD; Asian Center for Theological Studies and Mission-ACTS, Korea); Phaiza khua ah suak; AD 2009 in Yangon ah Ambassador Baptist Church phut; 2012 in EBC in siampi suahna pia; 2013 in Gospel Focus Publications pankhia; laibu bu (29) gelh khin aa; zolai tawh bu (25); kawllai tawh bu (3) leh manglai tawh bu (1) hi; Zolai tawh a gelhsa Pasian thute tungtawn-in Myanmar Biblical Christian Institute; Mission Training Center in February 20, 2016 in Honorary Doctorate of Literature (D.Litt.) tawh pahtawi; a zi, Dal Za Dim, tawh ta thum, Pau Sian Teel, Siam Geel Thang leh Zo Sian Leen, nei in Yangon ah teng hi.
                </Typography>

                <Typography variant="h6" gutterBottom>Lungdam Kohna</Typography>
                <Typography paragraph>
                    <ul>
                        <li>Tonglet thahatna ding-in sum-le-pai tawh hong panpih:<br/>ZBCA (Zomi Baptist Churches of America)</li>
                        <li>
                        Kammal tawh hong panpih kammal pupiteng: <br/>
                        Pu Vul Za Thang & Pu J. Gin Za Twang <br/>
                        Dr. Dal Lian Haokip, Ph.D. <br/>
                        Pu Thang Khan Dal  <br/>
                        Rev. Mang Cin Pau Gualnam, Ph.D. <br/>
                        </li>
                    </ul>
                </Typography>

                <Typography variant="h6" gutterBottom>Theihsakna</Typography>
                <Typography paragraph>
                    <ul>
                        <li>Hih Tonglet sungah kigelh zolaite in ‘zolai gualhzia’ azuih lian om nai lo ahih manin; kammal hong panpih kammal pupite’ gelh bangin ong kigelh hi. </li>
                        <li>Kammal na zon ciangin kammal khat simah kammal gelhte’ min kiguang tangtang hi. Tonglet sungah akiguang nailo Zolai-Manglai/Manglai-Zolai kammal thak kician takin na guanbeh leh, tua kammalte akizon simin nang min zong kidawk den ding hi. Kammal thak pen hi link <a href="https://bit.ly/3oFmMNv">https://bit.ly/3oFmMNv</a> panin kiguan beh thei hi. Kammal guanbeh napi cin kammal adawk kei leh endik pawlin endik lai aihkeh guanbeh dingin lem lo hi kha ding hi.</li>
                        <li>Zo laibu le Zo thuluii (articles) na guanbeh nop leh hi link <a href="https://bit.ly/2VUZ5Eu">https://bit.ly/2VUZ5Eu</a>panin ki guanbeh thei hi.</li>
                        <li>Aw (Zola) leh melmuh-tongsan (Zo Video) cihte ii link-te na guanbeh nop leh hi link <a href="https://bit.ly/2VUZ5Eu">https://bit.ly/2VUZ5Eu</a> panin kiguang thei hi.</li>
                    </ul>
                </Typography>

                <Typography variant="h6" gutterBottom>Kizop nading</Typography>
                <Typography paragraph>
                    <a href="mailto:tonglet.developer@gmail.com">tonglet.developer@gmail.com</a>
                </Typography>

                <Typography variant="h6" gutterBottom>Zahtakna lianpi tawh,</Typography>
                <Typography paragraph>
                    Penglam
                </Typography>


                
        </NavigationWrapper>
    );
}

export default PageAbout;