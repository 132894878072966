export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "tonglet-project.firebaseapp.com",
    databaseURL: "https://tonglet-project.firebaseio.com",
    projectId: "tonglet-project",
    storageBucket: "tonglet-project.appspot.com",
    messagingSenderId: "401199642314",
    appId: "1:401199642314:web:45fb0ad415df20b7406633",
    measurementId: "G-9Z3CYPL428"
};




 

