
import React from 'react';
import clsx from 'clsx';
import {
    makeStyles,
    useTheme,
    Drawer,
    CssBaseline,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    Button
} from '@material-ui/core/';

import {Context as LanguageContext} from '../context/LanguageContext';


import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';


import AboutPNG from '../../assets/About.png';
import AddBookPNG from '../../assets/AddBook.png';
import AddWordPNG from '../../assets/AddWord.png';
import DownloadPNG from '../../assets/Download.png';
import FeedbackPNG from '../../assets/Feedback.png';
import NamePNG from '../../assets/Name.png';
import PoemPNG from '../../assets/Poem.png';
import ProverbPNG from '../../assets/Proverb.png';
import SearchPNG from '../../assets/Search.png';
import LogoPNG from '../../assets/Logo.png';


import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FacebookIcon from '@material-ui/icons/Facebook';
import EditIcon from '@material-ui/icons/Edit';

import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    paper: {
        margin: 5,
        padding: 10,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
    },
    grow: {
        flexGrow: 1,
    },
    languageButton:  {
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    

}));


function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

const NavigationWrapper=({children})=>{

    const { state, changeEnglish, changeZolai } = React.useContext(LanguageContext);
    // start NAVIGATION
    const classes = useStyles();
    const theme = useTheme();
    /**
     * If Mobile, false.
     * If Desktop, true.
     */

    const [open, setOpen] = React.useState(!isMobile);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return(
        <div className={classes.root}>

            <CssBaseline />

            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })} >

                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}>

                        
                        
                        <MenuIcon />

                    </IconButton>

                    <Typography variant="h6" noWrap>Tonglet</Typography>
                    <div className={classes.grow} />
                    
                    {!open && isMobile && <Button variant="contained" style={{backgroundColor: '#ff9800', color: '#ffffff'}} startIcon={<MonetizationOnIcon />} component={Link} to={'/~donate'}>{state.donateMobile}</Button>}
                    {!isMobile && <Button variant="contained" style={{backgroundColor: '#ff9800', color: '#ffffff'}} startIcon={<MonetizationOnIcon />} component={Link} to={'/~donate'}>{state.donateDesktop}</Button>}
                    
                </Toolbar>
                

            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{ paper: classes.drawerPaper }} >
                
                <div className={classes.drawerHeader}>
                { /** Center this logo if you can, without marginRight: '25%' */  }
                <img src={LogoPNG} width="40" style={{marginRight: '25%'}}/>
                
                    <IconButton onClick={handleDrawerClose}>
                    

                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}

                    </IconButton>

                </div>
                

                <List>
                    <ListItem>
                        <div className={classes.languageButton}>
                            <Button    color={state.isZolai ? "primary" : ""}  onClick={()=> changeZolai()}>Zolai</Button>
                            <Button   color={state.isEnglish ? "primary" : ""} onClick={()=> changeEnglish()}>English</Button>
                        </div>
                    </ListItem>
                </List>

                <Divider />

                <List>
                    {[{ label: state.search, link: '/', icon: SearchPNG }, { label: state.about, link: '~about', icon: AboutPNG }].map((page, index) => (
                        <ListItem button key={page.label} component={Link} to={page.link} onClick={()=>{if(isMobile)handleDrawerClose()}}>
                            <ListItemIcon>
                            <img src={page.icon} width="25" height="25" />
                            </ListItemIcon>
                            <ListItemText primary={page.label} style={{color: '#3f51b5'}} />
                        </ListItem>
                    ))}
                </List>

                <Divider />

                <List>
                    {[{label: state.name, link: '~name', icon: NamePNG}, {label: state.poem, link: '~poem', icon: PoemPNG}, {label: state.proverb, link: '~proverb', icon: ProverbPNG},{label: state.editorial, link: '~editorial', icon: <EditIcon color="primary"/>, isIcon: true} ,{label: state.download, link: '~book', icon: DownloadPNG}, ].map((page, index) => (
                        <ListItem button key={page.label} component={Link} to={page.link} onClick={()=>{if(isMobile)handleDrawerClose()}}>
                            <ListItemIcon>
                             {page.isIcon ? page.icon : <img src={page.icon} width="25" height="25" />}
                            </ListItemIcon>
                            <ListItemText primary={page.label} style={{color: '#3f51b5'}} />
                        </ListItem>
                    ))}
                </List>
                
                <Divider />

                <List>
                    <ListItem>
                        <ListItemIcon><img src={FeedbackPNG} width="25" height="25" /></ListItemIcon>
                        <ListItemText style={{color: '#3f51b5'}} ><a href="http://m.me/tonglet.org" target="_blank" style={{color: 'inherit', textDecoration: 'none'}}>{state.feedback}</a></ListItemText>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon><img src={AddWordPNG} width="25" height="25" /></ListItemIcon>
                        <ListItemText style={{color: '#3f51b5'}} ><a href="https://forms.gle/NT5BzpKS6W9KBhdL7" target="_blank" style={{color: 'inherit', textDecoration: 'none'}}>{state.newWord}</a></ListItemText>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon><img src={AddWordPNG} width="25" height="25" /></ListItemIcon>
                        <ListItemText style={{color: '#3f51b5'}} ><a href="https://forms.gle/CSutbchRHsRZbw7X6" target="_blank" style={{color: 'inherit', textDecoration: 'none'}}>{state.newProverbPoem}</a></ListItemText>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon><img src={AddBookPNG} width="25" height="25" /></ListItemIcon>
                        <ListItemText style={{color: '#3f51b5'}} ><a href="https://forms.gle/NQCfAKP9S9oAoaeT6" target="_blank" style={{color: 'inherit', textDecoration: 'none'}}>{state.newBookArticle}</a></ListItemText>
                    </ListItem>

                    
                </List>
                <Divider />

                <List>

                    <ListItem onClick={()=> openInNewTab('https://www.facebook.com/tonglet.org')}>
                    <IconButton color="primary" aria-label="Facebook" component="span" >
                        <FacebookIcon />
                    </IconButton>

                    <ListItemText style={{color: '#3f51b5'}}> <a href="https://www.facebook.com/tonglet.org'" target="_blank" style={{color: 'inherit', textDecoration: 'none'}}>Tonglet on Facebook</a></ListItemText>
                    </ListItem>
                </List>


            </Drawer>

            

            <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                <div className={classes.drawerHeader} />
                {children}
                </main>
                </div>
    );
}

export default NavigationWrapper;