import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

import Client from './client';
import Admin from './admin';
import { Provider as LanguageProvider } from './client/context/LanguageContext';

import PageAbout from './client/pages/PageAbout';
import PageName from './client/pages/PageName';
import PagePoem from './client/pages/PagePoem';
import PageProverb from './client/pages/PageProverb';
import PageBook from './client/pages/PageBook';
import PageDonate from './client/pages/PageDonate';
import PageThank from './client/pages/PageThank';
import PageEditorial from './client/pages/PageEditorial';


function App() {
  return (
    <LanguageProvider>
     <React.Fragment>
     
       <BrowserRouter>
        <React.Fragment>
        
          <Route path="/" exact component={Client}/>
          <Route path="/~about" exact component={PageAbout}/>
          <Route path="/~name" exact component={PageName}/>
          <Route path="/~poem" exact component={PagePoem}/>
          <Route path="/~proverb" exact component={PageProverb}/>
          <Route path="/~book" exact component={PageBook}/>
          <Route path="/~donate" exact component={PageDonate}/>
          <Route path="/~thankyou" exact component={PageThank}/>
          <Route path="/~editorial" exact component={PageEditorial}/>
          <Route path="/~root" component={Admin}/>
          
        </React.Fragment>
       </BrowserRouter>
       
     </React.Fragment>
     </LanguageProvider>
  );
}

export default App;
