import React from "react";
import Autosuggest from "react-autosuggest";
import "./AutoCompleteInput.css";
import firebase from "firebase";
import _ from "lodash";

/* --------------- */
/*    Component    */
/* --------------- */

function getSuggestionValue(suggestion) {
  return suggestion.mainWord;
}

function renderSuggestion(suggestion) {
  return (
    <span>
      <strong>{suggestion.mainWord}</strong> | <i>{suggestion.wordType ? suggestion.wordType : null}</i> | {suggestion.definitionArray[0] ? suggestion.definitionArray[0] : null}
    </span>
  );
}

export default class AutoCompleteInput extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      suggestions: [],
      shouldUpdate: true,
    };
    this.debouncedLoadSuggestions = _.debounce(this.loadSuggestions, 500);
  }

   

  loadSuggestions(value) {
    if (value === this.state.value) {
      try {
        firebase
          .functions()
          .httpsCallable("clientapi/searchDocument")({
            key: "mainWord",
            keywords: value,
            sensitivity: false
          })
          .then((result) => {
            const suggestions = result.data;
            // console.log("AutoComplete: Retrieved suggestions ", suggestions);
            // Not elegant solution. debounce cancel() does not work.
            if (this.state.shouldUpdate) {
              this.setState({ suggestions});
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      shouldUpdate: true,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.handleSearch(suggestion);
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.label,
      value,
      onChange: this.onChange,
      onFocus: ()=>{console.log('focusing')},
      onFocusIn: ()=>{console.log('focusing in.')},
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          this.setState({
            shouldUpdate: false,
          });
          // console.log("Entering");
          this.props.handleSearch(e.target.value);
        }
      },
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    );
  }
}
