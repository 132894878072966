import React from "react";
import NavigationWrapper from "../components/NavigationWrapper";
import firebase from "../../config/FirebaseConfig";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import FullScreenDialog from "../components/FullScreenDialog";
 

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));



function PageBook() {
  const classes = useStyles();
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [url, setURL ] = React.useState(null);
  const [title, setTitle] = React.useState('');

  const handleURL = (url) => setURL(url);

  const handleOpen = (item)=>{
    if(item.fileType==='Laibu (Book)' || item.fileType==='Thuluii (Article)'){
      handleURL(item.textLink);
      setTitle(item.title);
    }else{
      var win = window.open(item.mediaLink, '_blank');
      win.focus();
    }
  }


  React.useEffect(() => {
    try {
      (async () => {
        const { data: result } = await firebase.functions().httpsCallable("clientapi/getBooks")();
        setItems(result.rows);
        setLoading(false);
        // console.log(result);
      })();
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <NavigationWrapper>
      
      {loading && (<div style={{ alignContent: "center", justifyContent: "center" }}> <CircularProgress /> </div>) /* @todos - center loading */ }
      
      {!loading && (
          
        
        <List className={classes.root}>
            {items && (items.length === 0) && <h1>No content yet</h1> }

          {items && (items.length > 0) && items.map((item) => (
            <React.Fragment>
              
              <ListItem alignItems="flex-start">
                
                <ListItemText primary={item.title} secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2"
                        // className={classes.inline} 
                        color="textPrimary">
                        {item.name}
                      </Typography>
                      {` — ${item.description}`}
                    </React.Fragment>
                  }/>
                
                <Button onClick={()=>handleOpen(item)}>
                  Open
                </Button>
                {url && <FullScreenDialog url={url} handleURL={handleURL} title={title}/>}

              </ListItem>


              <Divider component="li" />

            </React.Fragment>
          ))}
        </List>
      )}
      
      
    </NavigationWrapper>
  );
}

export default PageBook;
