import React from "react";
import NavigationWrapper from "../components/NavigationWrapper";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const PageDonate = () => {
  const classes = useStyles();

  return (
    <NavigationWrapper>
      <h1>
        {" "}
        <MonetizationOnIcon />Nong Panpihna tawh hih TONGLET kizom zo hi</h1>
      <Grid container spacing={3}>
        {[
          {
            gateway: "Paypal",
            description: "Accepts Paypal, Master, VISA, Credit, Debit cards",
            link: "https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=HJD9YQKGGMTME",
          },
          {
            gateway: "KBZ Bank",
            description: "Account person: Niang Theih Kim",
            account: "Account Number: 07 530 107 500 992 001",
            
          },
          {
            gateway: "KBZ Pay",
            description: "Account person: Niang Theih Kim",
            account: "Account Number: +95 09 420 024 161",
            
          },
          {
            gateway: "AYA Bank",
            description: "Account person: Niang Theih Kim",
            account: "Account Number: 0 156 201 010 005 791",
            
          },
        ].map((item) => (
          <Grid item xs={12} sm={3}>
            <Card className={classes.cardRoot}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.gateway}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {!item.account ? (
                  <Badge color="secondary" variant="dot">
                    <Button size="small" color="primary" onClick={() => { item.link && openInNewTab(item.link) }} >
                      Click to donate now
                    </Button>
                  </Badge>
                ) : 
                (
                  
                    <Button size="small" color="primary">
                      {item.account}
                    </Button>
                  
                )
                }

              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </NavigationWrapper>
  );
};

export default PageDonate;
