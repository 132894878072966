import React from 'react';
import NavigationWrapper from '../components/NavigationWrapper';


const PageThank=()=>{
    return(
        <NavigationWrapper>
                <h1> Nong HUHNA kiciamteh hi, lungdam. </h1>
            
        </NavigationWrapper>
    );
}

export default PageThank;