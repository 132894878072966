import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function parseGoogleDriveURL(openURL){
    const regex = /(.*)id=(.*)/gm;
    const match= regex.exec(openURL);
    return "https://drive.google.com/file/d/".concat(match[2]).concat("/preview");
}

export default function FullScreenDialog({title, url, handleURL }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    handleURL(null);
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>

            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>

            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          
          </Toolbar>
        </AppBar>
        <iframe src={parseGoogleDriveURL(url)} title="description" style={{height: '100%'}}/>
      </Dialog>
    </div>
  );
}
