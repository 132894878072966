import React from "react";

/**
 * 
 * Importing material
 * 
 */
import {
    CssBaseline,
    Container,
    makeStyles,
    Grid,
    Button,
    CircularProgress,
} from "@material-ui/core/";
import { Sync, VpnKey, ExitToApp } from "@material-ui/icons";

/**
 * 
 * Importing firebase
 * 
 */
import { firebaseConfig } from "../constants/FirebaseConstant";
import firebase from "firebase/app";
import "firebase/auth";
import  "firebase/functions";

// Initialising firebase configurations
firebase.initializeApp(firebaseConfig);

/**
 * 
 * @description - Theme Style
 * 
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));



/**
 * 
 * @description - RootContainer Component
 * 
 */
function RootContainer({ children }) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            <Container size="sm">
                <Grid container spacing={3} className={classes.root}>
                    {children}
                </Grid>
            </Container>
        </React.Fragment>
    );
}

/**
 * 
 * @description - Main Component
 * 
 */
const Index = () => {
    const classes = useStyles();
    const [user, setUser] = React.useState(null);
    const [authLoading, setAuthLoading] = React.useState(false);


    /**
     * @TODO - better to Verify ID Tokens
     */
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((userAuth) => {
            // setting use state
            setUser(userAuth);
        });
    }, [user]);



    /**
     * 
     * @description - Authentication Button
     * 
     * */
    function AuthButton() {
        const endIcon = user == null ? (authLoading ? (<CircularProgress size={15} />) : (<VpnKey />)) : (<ExitToApp />);
        const text = user === null ? authLoading ? "Loading..." : "Login with Google" : authLoading ? "Loading..." : user.email + " Logout ";

        // Event handler for authentication button
        const handleAuthClick = {
            /**
             * @TODO - login successful only with one account
             */
            signIn: () => {
                setAuthLoading(true);
                const provider = new firebase.auth.GoogleAuthProvider();
                firebase.auth().signInWithPopup(provider).then(function (result) {
                    setUser(result.user);
                    setAuthLoading(false);
                }).catch(function (error) {
                    console.log(error);
                });
            },
            signOut: () => {
                setAuthLoading(true);
                firebase.auth().signOut().then(function () {
                    setUser(null);
                    setAuthLoading(false);
                }).catch(function (error) {
                    console.log(error);
                });
            },
        };

        const action = user == null ? handleAuthClick.signIn : handleAuthClick.signOut;

        

        return (
            <Button
                onClick={action}
                variant="outlined"
                color="primary"
                endIcon={endIcon}
                disabled={authLoading}
            >
                {" "} {text} {" "}
            </Button>
        );
    }



    /**
     * 
     * @description - A Component for Synchronization
     * 
     */
    function SyncButton() {
        const [syncStatus, setSyncStatus] = React.useState("initialising");
        const [sheetProgress, setSheetProgress] = React.useState(1);
        const [syncResult, setSyncResult] = React.useState(null);
        const [syncLoading, setSyncLoading] = React.useState(false);
        const endIcon = syncLoading ? <CircularProgress size={15} /> : <Sync />;
        const refTotalSheet = React.useRef(0);

        React.useEffect(() => {
            /**
             * Remark
             *  - Promise.prototype.then() does not work.
             *  - Use IIFE
             */
            (async () => {
                const { data: response } = await firebase.functions().httpsCallable("app/getSheetInfo")();
                refTotalSheet.current = response.rowCounts.length;
                setSyncResult(response);
                setSyncStatus("loaded");
            })();
        }, []);

        // Event handler for Sync Button
        const handleClick = async () => {
            console.log(`Current Sheet: ${sheetProgress}, Total Sheets: ${syncResult.rowCounts.length}, Sheet Positions: ${syncResult.positions}\n`);


            setSyncLoading(true);
            const batchLimit = 1000;

            // if the first sheet prepare
            if (sheetProgress === 1) {
                // Call `prepareInsert` to empty out existing collection and set the system status as inactive
                await firebase.functions().httpsCallable("app/preInsert")();
            }

            // // Call `beginInsertion` to bulk write rows into database

            // // Loop by Worksheet
            const index = sheetProgress - 1;
            const totalSize = syncResult.rowCounts[index];
            const chunkSize =
                totalSize % batchLimit !== 0
                    ? Math.trunc(totalSize / batchLimit + 1)
                    : Math.trunc(totalSize / batchLimit);

            // Loop by rows chunk by batchLimit
            for (var i = 1; i <= chunkSize; i++) {
                const next = i === chunkSize ? totalSize : i * batchLimit;
                const current = i === 1 ? 0 : (i - 1) * batchLimit + 1;

                try {
                    await firebase.functions().httpsCallable("app/beginInsert")({
                        startLoop: current,
                        endLoop: next,
                        index: syncResult.positions[index],
                    });
                    console.log({
                        startLoop: current,
                        endLoop: next,
                        index: syncResult.positions[index],
                    });
                } catch (error) {
                    setSyncLoading(false);
                    console.log(error);
                    break;
                }


            }

            const { data: result } = await firebase.functions().httpsCallable("app/diff")({
                bookRef: syncResult.bookRefs[index],
                maxRow: syncResult.rowCounts[index],
            });

            // If the last sheet, create index
            if (sheetProgress === refTotalSheet.current) {
                // Call `postInsert` to create index of mongodb for querying performance and set the system status as active
                await firebase.functions().httpsCallable("app/postInsert")();
                setSyncLoading(false);
                setSyncStatus("completed");
            } else {
                console.log(result);
                setSyncLoading(false);
                setSheetProgress(sheetProgress + 1);
            }



        };

        // not secure approach for backend but well, backend has secured! I think.
        if(user && (user.email===process.env.REACT_APP_USER_ONE || user.email===process.env.REACT_APP_USER_TWO || user.email===process.env.REACT_APP_USER_THREE)){
            switch (syncStatus) {

                case "initialising":
                    return <Button disabled>Initialising...</Button>;
    
                case "loaded":
                    return (
                        <Button onClick={handleClick} endIcon={endIcon} disabled={syncLoading}>
                            {syncLoading ? `Sync... ${sheetProgress} of ${refTotalSheet.current}` : `Sync next ${sheetProgress}`}
                        </Button>
                    );
    
                case "completed":
                    return <Button disabled>Sync completed</Button>;
    
                default:
                    return null;
            } 
        }
        return null;
       

        

    }

    return (
        <RootContainer>
            <Grid item xs={12}>
                <div className={classes.paper}>
                    <AuthButton />
                </div>
            </Grid>
            {user && (

                <Grid item xs={12}>
                    <div className={classes.paper}>
                        <SyncButton />
                    </div>
                </Grid>

            )}
        </RootContainer>
    );
};

export default Index;

/**
 *
 * @todos - code refactoring
 * @todos - check max documents in MongoDB and sheety max row
 * @todos - remove components out of Main Component
 *
 *
 * Downside of API/subAPI for a function is
 */
