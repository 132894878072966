import React, { useReducer } from 'react';

/*
 * ဂိတ်ပေါက် => Reducers, Actions, Initials 
 * ထွက်ပေါက် => Context, Provider
 */

export default (reducer, actions, initialState) => {
    // တည်ဆောက် => Context
    const Context = React.createContext();
    // တည်ဆောက် => Context.Provider 
    const Provider = ({ children }) => {

        /* 
         * တည်ဆောက် => useReducer
         * ခေါ်ယူ => Reducer, Initials
         * ထုတ်ပေး => State, Dispatch
         */
        const [state, dispatch] = useReducer(reducer, initialState);
        // console.log(dispatch.toString().toSource());
        // ထည့်သွင်း => Actions တွင် လိုအပ်နေတဲ့ Dispatch ကို Parameter ထည့်သွင်း
        const boundActions = {};
        for (let key in actions) {
            boundActions[key] = actions[key](dispatch);
        }

        return (
            <Context.Provider value={{ state, ...boundActions }}>
                {children}
            </Context.Provider>
        );
    }

    return { Context, Provider }
}