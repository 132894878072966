
// Context နဲ့ Provider ထုတ်လို့ရအောင် မိခင် Context Management ကို ခေါ်လိုက်တယ်။
import createDataContext from './createDataContext';

const initialState = {
    search: 'Zong', 
    about: 'Athu',
    name: 'Min', 
    poem: 'Lakam',
    proverb: 'Paunak',
    download: 'Lakhia',
    feedback: 'Ngaihsutna pia',
    newWord: 'Kammal behlap',
    newProverbPoem: 'Lakam/Paunak behlap',
    newBookArticle: 'Laibu/Thuluii behlap',
    donateDesktop: 'PANPIHNA KIPIA THEI HI',
    donateMobile: 'PANPIHNA',
    editorial: 'Zolai Endik',
    isZolai: true,
    isEnglish: false,
}

const englishLanguageState = {
    search: 'Search', 
    about: 'About',
    name: 'Name', 
    poem: 'Poem',
    proverb: 'Proverb',
    download: 'Download',
    feedback: 'Feedback',
    newWord: 'Add a new word',
    newProverbPoem: 'Add a poem/proverb',
    newBookArticle: 'Add a(an) book/article',
    donateDesktop: 'Support',
    donateMobile: 'Support',
    editorial: 'Zolai Editorial',
    isZolai: false,
    isEnglish: true
}

/**
 * data တစ်ခုနဲ့ သူ့နဲ့ ဆက်စပ်မဲ့ CRUD function
 * state ဆိုတာ data, action ဆိုတာ function
 */
const languageReducer = (state, action) => {
    switch (action.type) {
        case 'change_to_english':
            console.log('change to english');
            return action.payload;
        case 'change_to_zolai':
            console.log('change to zolai');
            return action.payload;
        default:
            console.log('calling default action');
            return state;
    }
}

 

// Actions change to English
const changeEnglish = dispatch => {
    return () => {
        dispatch({ type: `change_to_english`, payload: englishLanguageState });
    }
}

// Actions change to Zolai
const changeZolai = dispatch => {
    return () => {
        dispatch({ type: `change_to_zolai`, payload: initialState });
    }
}



// ထုတ်မဲ့အကောင် (Context, Provider) = ဘယ်ကနေ (reducer, actions, initialState)
export const { Context, Provider } = createDataContext( languageReducer, { changeEnglish, changeZolai }, initialState);