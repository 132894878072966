import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Chip,
  Paper,
  CircularProgress,
} from "@material-ui/core/";

import FaceIcon from "@material-ui/icons/Face";
import NavigationWrapper from "./components/NavigationWrapper";
import AutoCompleteInput from "./components/AutoCompleteInput";

import {Context as LanguageContext } from './context/LanguageContext';

import firebase from "firebase";
import { isObject } from "lodash";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  paper: {
    margin: 5,
    padding: 10,
  },
}));

export default function Index(props) {

  const {state} = React.useContext(LanguageContext);
  const [searchWord, setSearchWord] = React.useState(null);
  const [items, setItems] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const classes = useStyles();

 

  const handleSearch = async (word) => {
    setItems(null);

    setStatus("LOADING");

    if (isObject(word)) 
    {
      setSearchWord(word);

      // querying for search results
      const { data: collectionResult } = await firebase.functions().httpsCallable("clientapi/getDocumentCollection")({
        keywords: word.origin,
      });

      setItems(collectionResult);

      setStatus("SUCCESS");
    } 
    else {
      const keyArray = [
        "mainWord",
        "v2Verb",
        "v3Verb",
        "vingVerb",
        "pluralNoun",
      ];

      const keySize = keyArray.length;

      for (var i = 0; i < keySize; i++) {
        // querying for a word from 'Enter' event from 'mainWord'
        // console.log("Searching for ", keyArray[i]);

        const { data: result } = await firebase
          .functions()
          .httpsCallable("clientapi/searchDocument")({
          key: keyArray[i],
          keywords: word,
          sensitivity: true,
        });

        if (
          result[0] === undefined &&
          i !== keySize - 1 &&
          result.length === 0
        ) {
          // console.log("Result not found in ", keyArray[i]);
          if (i === keySize - 1) {
            // console.log("Result really not founed.");
            setStatus("NOT_FOUND");
          }
        } else {
          if(result[0]===undefined){
            // console.log("Result NOT FOUND");
            setStatus("NOT_FOUND");
            setSearchWord(word);
            break;
          }
          // console.log("Result success.", result[0]);
          setSearchWord(result[0]);

          // querying for search results
          const { data: collectionResult } = await firebase.functions().httpsCallable("clientapi/getDocumentCollection")({
            keywords: result[0].origin,
          });

          setItems(collectionResult);

          setStatus("SUCCESS");
          break;
        }
      }
    }
  };

  return (
    <NavigationWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AutoCompleteInput
            history={props.history}
            handleSearch={handleSearch}
            label={state.search}
          />
        </Grid>

        {status === "LOADING" &&  <div style={{margin: 'auto', alignContent: 'center'}}><CircularProgress /></div> }
        {status === "NOT_FOUND" && <div style={{margin: 'auto', alignContent: 'center'}}><Typography>"{searchWord}" NOT FOUND. If you know the meaning, please click <a  target="_blank" href={`https://docs.google.com/forms/d/e/1FAIpQLScz2sVqK75uafXHKQs7zExgtXKCr0ApITLqIpTp86sCMhRmhQ/viewform?usp=pp_url&entry.614264233=${searchWord}`}>here</a> to add.</Typography></div>}
        {status === "SUCCESS" && (
          <React.Fragment>
            <Grid item xs={12}>
              {/** Search Word Title */}
              <Typography variant="h4" gutterBottom>
                {searchWord && searchWord.mainWord}
              </Typography>

              {/** DefintionArray Chip */}
              <Typography>
                {searchWord &&
                  searchWord.definitionArray.map((item) => (
                    <Chip
                      label={item.toUpperCase()}
                      style={{ margin: 5 }}
                      variant="outlined"
                    />
                  ))}
              </Typography>
            </Grid>

            {/** All Collection Meaning */}

            {items &&
              items.map((item) => (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Chip
                        label={item.mainWord}
                        clickable
                        color="primary"
                        style={{ margin: 3 }}
                      />

                      {item.wordType && 
                      <Chip
                        label={item.wordType}
                        clickable
                        color="primary"
                        variant="outlined"
                        style={{ margin: 3 }}
                      />
                      }

                      {item.field && 
                      <Chip
                        label={ item.field }
                        variant="outline"
                        style={{ margin: 3 }}
                      />
                      }

                      <div style={{ margin: 10 }}>
                        {/** Definition Array */}

                        {item.definitionArray && (
                          <Typography style={{ margin: 10 }}>
                            {item.definitionArray.join(", ")}
                          </Typography>
                        )}

                        

                        {/** Example Array */}

                        {item.exampleArray instanceof Array &&
                          item.exampleArray.length > 0 && (
                            <React.Fragment>
                              <Typography
                                variant="overline"
                                display="block"
                                gutterBottom
                              >
                                EXAMPLE:
                              </Typography>

                              <Typography>
                                {item.exampleArray.join(", ")}
                              </Typography>
                              <br />
                            </React.Fragment>
                          )}

                        {/** Synonyms Array */}

                        {item.synonymArray instanceof Array &&
                          item.synonymArray.length > 0 && (
                            <React.Fragment>
                              <Typography
                                variant="overline"
                                display="block"
                                gutterBottom
                              >
                                SYNONYMS:
                              </Typography>

                              <Typography>
                                {item.synonymArray.join(", ")}
                              </Typography>
                              <br />

                              <br />
                            </React.Fragment>
                          )}

                        {/** Verb 2 */}

                        {item.v2Verb && (
                          <Chip
                            label={item.v2Verb}
                            variant="outline"
                            style={{ margin: 3 }}
                          />
                        )}

                        {/** Verb 3 */}

                        {item.v3Verb && (
                          <Chip
                            label={item.v3Verb}
                            variant="outline"
                            style={{ margin: 3 }}
                          />
                        )}

                        {/** Verb Ving */}

                        {item.vingVerb && (
                          <Chip
                            label={item.vingVerb}
                            variant="outline"
                            style={{ margin: 3 }}
                          />
                        )}

                        {item.pluralNoun && (
                          <Chip
                            label={item.pluralNoun}
                            variant="outline"
                            style={{ margin: 3 }}
                          />
                        )}
                        <br/><br/>
                        <Chip icon={<FaceIcon />} label={item.credit} style={{alignContent: 'flex-end'}} />
                      </div>
                    </Paper>
                  </Grid>
                </React.Fragment>
              ))}

            
          </React.Fragment>
        )}
      </Grid>
    </NavigationWrapper>
  );
}

