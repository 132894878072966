import React from "react";
import SentenceTranslationComponent from "../components/SentenceTranslationComponent";

const PageName = () => {
  return (
    <SentenceTranslationComponent
      title="Min khiatna"
      docType="name"
    />
  );
};

export default PageName;

/**
 * @todo - Think about
 */
