import React from "react";
import NavigationWrapper from "./NavigationWrapper";
import firebase from "../../config/FirebaseConfig";
import {
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  CircularProgress,
  TextField,
} from "@material-ui/core";

const SentenceTranslationComponent = ({title,docType}) => {
  const [loading, setLoading] = React.useState(true);
  const [listItemState, setListItemState] = React.useState(null);
  const listItemRef = React.useRef([]);
  const [keyword, setKeyWord] = React.useState('');
  

  React.useEffect(() => {
    try{
      (async () => {
        const { data: result } = await firebase
          .functions()
          .httpsCallable("clientapi/getDocument")({
          docType,
        });
        listItemRef.current = result;
        setTimeout(() => {
          setListItemState(result);
          setLoading(false);
        }, 500);
        
      })();
    }catch(e){
      console.log(e);
    }
  }, []);

  function handleSearch(keyword, items) {
    setKeyWord(keyword);
    const listItems = items.filter((item) => item.mainWord.toLowerCase().includes(keyword.toLowerCase()) || item.definitionArray[0].toLowerCase().includes(keyword.toLowerCase()));
    setListItemState(listItems);
  }

  return (
    <NavigationWrapper>
      {loading && <div style={{ alignContent: 'center', justifyContent: 'center'}}><CircularProgress /></div> /* @todos - center loading */}

      {!loading && (
        <>
          <Typography variant="h4">{title}</Typography>
          <Divider />
          <br />
          <TextField
            id="standard-basic"
            label="Search"
            style={{ width: "100%" }}
            onChange={(e) => handleSearch(e.target.value, listItemRef.current)}
          />
          <List>
            {listItemState.map((item) => (
              <ListItem key={item._id}>
                <Paper style={{ width: "100%", padding: 30 }}>
                  <ListItemText
                    primary={item.mainWord.charAt(0).toUpperCase().concat(item.mainWord.slice(1))}
                    secondary={item.definitionArray[0]/* [0] Because they only have one meaning*/}
                  />
                </Paper>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </NavigationWrapper>
  );
};

export default SentenceTranslationComponent;

/**
 * @todo - Think about
 */
